import styles from "./Dashboard.module.scss"
import DashboardHeader from "./DashboardHeader"
import DashboardRoutes from "./DashboardRoutes"
import DashboardSidebar from "./DashboardSidebar"

const Dashboard = () => {

  return <div className={styles.mainContainer}>
    <DashboardHeader/>
    <section className={styles.mainWrapper}>
      <DashboardSidebar/>
      <section className={styles.main}>
        <DashboardRoutes/>
      </section>
    </section>
  </div>
}
export default Dashboard;
