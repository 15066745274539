const routes = {
  login: "/login",
  dashboard: "/dashboard",
  announce: {
    path: "announce",
    add: "add",
    published: "published",
    needApprovals: "need-approvals",
    inProgress: "in-progress",
    resolved: "resolved",
    needActive: "need-active",
  },
  user: {
    path: "user",
    trainers: "trainers",
    clients: "clients"
  }
}
export default routes
