import { useSelector } from "react-redux"
import { Navigate, Route, Routes } from "react-router-dom"
import routes from "../../constants/routes"
import Dashboard from "../Dashboard/Dashboard"
import Login from "../Login/Login"

const AppRoutes = () => {
  const user = useSelector((state) => state.user.value)

  return (
    <Routes>
      {["/", routes.login].map((path) =>
        <Route key={path} path={path} element={<>
          {user?.id && <Navigate to={routes.dashboard}/>}
          {!user?.id && <Login/>}
        </>}/>
      )}

      <Route path={`${routes.dashboard}/*`} element={<>
        {!user?.id && <Navigate to={routes.login}/>}
        {user?.id && <Dashboard/>}
      </>}/>

    </Routes>
  )
}

export default AppRoutes;
