const config = {
  endpointBaseUrl: "https://api.fitlog.io",
  basicAuth: "Basic Zml0bG9nOmZpdGxvZw==",
  translateAPI: 'https://translate.fitlog.ca',
  i18nAddPath: "/locales/add/{{lng}}/{{ns}}",
  i18nAddPathAuthorization: "Basic Zml0bG9nOmZpdGxvZw==",
  toastError: true
}

export default config;
