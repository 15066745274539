import { createSlice } from "@reduxjs/toolkit"

const getInitState = () => {
  try {
    let tmp = localStorage.getItem("app-auth")
    let authInfo = JSON.parse(tmp)
    authInfo.userid = authInfo.userId
    return authInfo
  } catch (err) {
    return {}
  }
}
export const userSlice = createSlice({
  name: "user",
  initialState: { value: getInitState() },
  reducers: {
    setUserInfoAction: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserInfoAction } = userSlice.actions

export default userSlice.reducer
