import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { NavLink, useLocation } from "react-router-dom"
import sidebarNavItems, { routesMapByUri } from "../../constants/sidebarNavItems"
import styles from "./Dashboard.module.scss"

const DashboardSidebar = () => {
  const location = useLocation();
  const { t } = useTranslation("admin");
  const [selectedNav, setSelectiveNav] = useState(sidebarNavItems[0]);

  useEffect(() => {
    const composeIndex = Number(routesMapByUri[location.pathname]?.split("_")[0]);
    !isNaN(composeIndex) && composeIndex > -1 && setSelectiveNav(sidebarNavItems[composeIndex]);
  }, [location]);

  return (
    <aside className={styles.aside}>
      <div className={styles.navItem}>
        <div className={styles.icons}>
          {sidebarNavItems.map((nav, index) => (
            <NavLink key={nav.key} to={nav.to ?? nav.items?.[0]?.to}
                     className={selectedNav?.key === nav.key ? "active" : ""}>
              {nav.icon}
              {nav.i18nKey ? t(nav.i18nKey) : nav.title}
            </NavLink>
          ))}
        </div>
        <div className={styles.items}>
          <div className={styles.title}>{selectedNav.title}</div>
          {selectedNav.items.map(sub => (
            <NavLink key={sub.key} to={sub.to} className={(navData) => (navData.isActive ? "active" : "")}>
              {sub.i18nKey ? t(sub.i18nKey) : sub.title}
            </NavLink>
          ))}
        </div>
      </div>
    </aside>
  )
}
export default DashboardSidebar
