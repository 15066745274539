import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import { IconButton } from "@mui/material"
import { Container } from "@mui/system"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import pkgJson from "../../../package.json"
import api from "../../api/api"
import { setUserInfoAction } from "../../store/reducers/userReducer"
import styles from "./Dashboard.module.scss"

const DashboardHeader = () => {
  const { t } = useTranslation(["common"])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.value)

  const handleLogout = () => {
    api.logout(user)
      .then(resp => {
        localStorage.removeItem("app-auth")
        dispatch(setUserInfoAction({
          userid: 0,
        }))
        navigate("/login")
      })
  }

  return <header className={styles.header}>
    <Container maxWidth={false} className={styles.container}>
      <div className={styles.brand}>
        <img src="/assets/img/logo.png" alt="logo" loading="lazy" height="40px"/>
        <div className={styles.version}>v{pkgJson.version}</div>
      </div>
      <IconButton onClick={handleLogout} color="primary" aria-label={t("common:logout")} component="label"
                  title={t("common:logout")}>
        <ExitToAppIcon/>
      </IconButton>
    </Container>
  </header>
}
export default DashboardHeader;
