import React, { Fragment, Suspense } from "react"
import { Route, Routes, } from "react-router-dom"
import routes from "../../constants/routes"

const AnnounceAdd = React.lazy(() => import("../Announce/AnnounceAdd"));
const AnnounceEdit = React.lazy(() => import("../Announce/AnnounceEdit"));
const ApprovalsList = React.lazy(() => import("../Announce/List/Approvals"));
const ResolvedsList = React.lazy(() => import("../Announce/List/Resolved"));
const InProgressList = React.lazy(() => import("../Announce/List/inProgress"));
const AnnouncementList = React.lazy(() => import("../Announce/List/Announcement"));
const ActiveList = React.lazy(() => import("../Announce/List/Activation"));

const DashboardRoutes = () => {
  return <Suspense fallback={<Fragment></Fragment>}>
    <Routes>
      <Route path={`${routes.announce.path}/*`}>
        <Route path={`edit/:id`} element={<AnnounceEdit />} />
        <Route path={`${routes.announce.add}`} element={<AnnounceAdd />} />
        <Route path={`${routes.announce.published}`} element={<AnnouncementList />} />
        <Route path={`${routes.announce.needApprovals}`} element={<ApprovalsList />} />
        <Route path={`${routes.announce.resolved}`} element={<ResolvedsList />} />
        <Route path={`${routes.announce.inProgress}`} element={<InProgressList />} />
        <Route path={`${routes.announce.needActive}`} element={<ActiveList />} />
      </Route>

    </Routes>
  </Suspense>
}
export default DashboardRoutes;
