import i18n from "i18next"
import XhrBackend from "i18next-xhr-backend"
import { initReactI18next } from "react-i18next"
import config from "../config"

const lang = localStorage.getItem("app-lang") || "en"
const translationBase = "https://translate.fitlog.ca"
const isProd = !config.i18nAddPath

const getBackendConfig = () => {
  const ret = {
    loadPath: `${translationBase}/lang/{{lng}}/{{ns}}.json`,
  }

  if (config.i18nAddPath) {
    ret.addPath = `${translationBase}${config.i18nAddPath}`

  }

  if (config.i18nAddPathAuthorization) {
    ret.customHeaders = {
      Authorization: config.i18nAddPathAuthorization
    }
  }
  return ret
}

i18n
  .use(XhrBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: lang,
    fallbackLng: "en",
    saveMissing: !isProd,
    debug: false,
    ns: ["common", "error"],
    defaultNS: "common",
    backend: getBackendConfig(),
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: true
    }
  })

export default i18n;
