import { createTheme, CssBaseline, ThemeProvider } from "@mui/material"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../../lang/i18n"
import "./App.scss"
import AppRoutes from "./AppRoutes"

const theme = createTheme({
  palette: {
    primary: {
      main: "#f39124"
    },
    secondary: {
      main: "#FFF"
    }
  }
});

function App() {
  return (
    <main className="app">
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <AppRoutes/>
      </ThemeProvider>
      <ToastContainer/>
    </main>
  );
}

export default App;
