import qs from "querystring"
import { toast } from "react-toastify"
import config from "../config"
import reduxStore from "../store/"

const getStore = () => reduxStore

function _getUser() {
  return getStore().getState().user.value
}

const checkErr = resp => {
  if (resp.ok) {
    if (resp.headers.get(("content-type")).indexOf("json") > 0)
      return resp.json()
    return resp
  }
  return resp.json()
    .then(json => {
      let error = Object.assign({}, json.error, { status: resp.status })
      handleError(error)
      throw (error)
    })
}

const handleError = err => {
  if (config.toastError)
    toast.error(err.message)
}

function isEmail(email) {
  /* eslint-disable no-useless-escape */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email.trim())
}

// options: {withAuthToken, method}
function prepare(options) {
  const { withAuthToken, method } = options
  const user = _getUser()
  if (withAuthToken && !user.id) {
    user.id = qs.parse(window.location.search).access_token
  }
  const headers = new Headers()
  headers.append("Content-type", "application/json")
  if (options.basicAuth)
    headers.append("Authorization", user.id)
  else if (config.basicAuth)
    headers.append("Authorization", config.basicAuth)

  return function Requesting(url, data, opts = {}) {
    const { query, throwErrorToMe, processFnc } = opts
    const allQuery = Object.assign(
      {},
      {
        ...(withAuthToken && !options.basicAuth
          ? { access_token: user.id }
          : {}),
      },
      query
    )
    const queryString = qs.encode(allQuery)

    const endpoint = `${config.endpointBaseUrl}${url}?${queryString}`
    const fetchOptions = {
      method,
      headers: headers
    }
    if (data)
      fetchOptions.body = JSON.stringify(data)

    return fetch(endpoint, fetchOptions)
      .then(resp => {
        if (processFnc)
          return processFnc(resp)
        else
          return checkErr(resp)
      })
      .catch(err => {
        if (throwErrorToMe)
          throw (err)
        else
          handleError(err)
      })

  }
}

const api = {
  get(url, opts = { basicAuth: false }, optToken) {

    return prepare({
      method: "get",
      withAuthToken: true,
      basicAuth: opts.basicAuth
    })(url, null, opts)

  },

  // by default delete returns no data
  // just status
  delete(url) {
    return prepare({
      method: "delete",
      withAuthToken: true
    })(url, null, {
      processFnc: resp => resp
    })
  },

  // put or patch
  // using the same name since I config on loopback side put = patch; and loopback doesnt expose PATCH method for related models
  patch(url, data = {}, isPut = false, opts = {}) {

    return prepare({
      method: isPut ? "PUT" : "PATCH",
      withAuthToken: true
    })(url, data, opts)
  },

  logout(user) {
    return fetch(`${config.endpointBaseUrl}/api/FitlogUsers/logout?access_token=${user.id}`, {
      method: "POST"
    })
  },

  // needed for register where accesstoken cant exist
  // refactor this
  postWithoutToken(url, data = {}, throwErrorToMe = false, processFnc) {

    return prepare({
      method: "post",
      withAuthToken: false
    })(url, data, {
      throwErrorToMe,
      processFnc
    })
  },

  post(url, data = {}, throwErrorToMe = false, processFnc) {

    return prepare({
      method: "post",
      withAuthToken: true
    })(url, data, {
      throwErrorToMe,
      processFnc
    })
  },

  login(unOrEmail, pw) {
    let credentials = {
      password: pw
    }
    if (isEmail(unOrEmail)) {
      credentials.email = unOrEmail
      credentials.hasEmail = true
    } else {
      credentials.username = unOrEmail
    }

    let notAuthorized = false
    if (credentials.hasEmail) {
      notAuthorized = ["khaledoft@hotmail.com", "khaled@fitlog.ca"].indexOf(credentials.email) === -1
    } else {
      notAuthorized = ["khaled"].indexOf(credentials.username) === -1
    }

    if (notAuthorized) {
      return new Promise((_,reject) => {
        return reject(handleError({
          "statusCode": 400,
          "name": "manual_error",
          "message": "Invalid credentials.",
          "status": 400,
          "serverErrorCode": 1,
        }))
      })
    }

    return this.postWithoutToken("/api/Credentials/login", credentials, true)

  }
}

export default api;
