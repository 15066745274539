import qs from "query-string"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import pkgJson from "../../../package.json"
import api from "../../api/api"
import Button from "../../components/MaterialUI/Button/Button"
import TextField from "../../components/MaterialUI/TextField/TextField"
import config from "../../config"
import routes from "../../constants/routes"
import { getFormData } from "../../helpers/formHelper"
import userHelper from "../../helpers/userHelper"
import styles from "./Login.module.scss"

const Login = () => {
  const { t } = useTranslation(["login"])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const formData = getFormData(e.target);

    api.login(formData.username, formData.password)
      .then(async (resp) => {
        setLoading(false)
        if (resp.id) {
          userHelper.handleAccessToken(resp, dispatch)
          const q = qs.parse(window.location.search)
          const next = q.redirect || routes.dashboard
          navigate(next)
        }
      })
      .catch(err => {
        setLoading(false)
      })
  }

  return <>
    <div className={styles.loginWrapper}>

      <section className={styles.formWrapper}>
        <div className={styles.imgWrapper}>
          <img src="/assets/img/logo.png" alt="logo" loading="lazy" width="50%"/>
        </div>

        <form onSubmit={handleSubmit}>
          <TextField label={t("login:username")} name="username" fullWidth required/>
          <TextField label={t("login:password")} name="password" type="password" fullWidth required/>
          <Button type="submit" loading={loading} fullWidth>{t("login:login")}</Button>
        </form>
      </section>

      <div className={styles.version}>v{pkgJson.version}</div>
      <div className={styles.version}>{config.endpointBaseUrl}</div>
    </div>
  </>
}

export default Login
