import { setUserInfoAction } from "../store/reducers/userReducer"

const userHelper = {
  handleAccessToken: (resp, dispatch) => {
    localStorage.setItem("app-auth", JSON.stringify(resp))
    dispatch(setUserInfoAction(resp))
  }
}

export default userHelper
