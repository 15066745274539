import PeopleIcon from "@mui/icons-material/People"
import SupportAgentIcon from "@mui/icons-material/SupportAgent"
import routes from "./routes"

const sidebarNavItems = [
  {
    key: "support_center",
    title: "Support Center",
    i18nKey: "admin:support",
    icon: <SupportAgentIcon/>,
    items: [
      {
        key: "publish_announce",
        title: "Publish Announce",
        i18nKey: "admin:publishAnnounce",
        to: `/dashboard/${routes.announce.path}/${routes.announce.add}`,
      },
      {
        key: "published_announcements",
        title: "Published Announcements",
        i18nKey: "admin:publishAnnouncements",
        to: `/dashboard/${routes.announce.path}/${routes.announce.published}`,
      },
      {
        key: "need_approvals",
        title: "Need Approvals",
        i18nKey: "admin:need-approvals",
        to: `/dashboard/${routes.announce.path}/${routes.announce.needApprovals}`,
      },
      {
        key: "Resolved",
        title: "Resolved",
        i18nKey: "admin:resolved",
        to: `/dashboard/${routes.announce.path}/${routes.announce.resolved}`,
      },
      {
        key: "in_pregress",
        title: "In Progress",
        i18nKey: "admin:in-progress",
        to: `/dashboard/${routes.announce.path}/${routes.announce.inProgress}`,
      },
      {
        key: "not_active",
        title: "Not Active",
        i18nKey: "admin:not-active",
        to: `/dashboard/${routes.announce.path}/${routes.announce.needActive}`,
      }
    ]
  },
  {
    key: "users",
    title: "Users",
    i18nKey: "admin:users",
    icon: <PeopleIcon/>,
    items: [
      {
        key: "trainers",
        title: "trainers",
        i18nKey: "admin:trainers",
        to: `/dashboard/${routes.user.path}/${routes.user.trainers}`,
      },
      {
        key: "clients",
        title: "clients",
        i18nKey: "admin:clients",
        to: `/dashboard/${routes.user.path}/${routes.user.clients}`,
      },

    ]
  }
]

const routesMap = sidebarNavItems.reduce((acc, current, parentIndex) => {
  Object.assign(acc, current.items.reduce((result, item, itemIndex) => {
    const key = `${parentIndex}_${itemIndex}`;
    result[key] = item.to;
    return result
  }, {}))
  return acc
}, {});

const routesMapByUri = sidebarNavItems.reduce((acc, current, parentIndex) => {
  Object.assign(acc, current.items.reduce((result, item, itemIndex) => {
    const key = item.to;
    result[key] = `${parentIndex}_${itemIndex}`;
    return result
  }, {}))
  return acc
}, {});

export default sidebarNavItems
export { routesMap, routesMapByUri }
