import { Button as MuiButton, CircularProgress } from "@mui/material"

const Button = ({ loading, children, ...props }) =>
  <MuiButton variant="contained" {...props}>
    {!loading && children}
    {loading && <CircularProgress color="inherit" size={24}/>}
  </MuiButton>


export default Button
